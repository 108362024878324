import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/joy/styles';
import App from './App';
import {getGlobal, setGlobal} from './components/Globals';
import { extendTheme } from '@mui/joy/styles';

setGlobal({ endpoint : "https://api.plhitalia.webdistrict.it/web/" })


if (sessionStorage.getItem('userId') && getGlobal("userID") === null) {
    setGlobal({ userID : sessionStorage.getItem('userId') })
    setGlobal({ isAdmin : sessionStorage.getItem('isAdmin')  })
}

ReactDOM.createRoot(document.querySelector("#root")!).render(

        <StyledEngineProvider injectFirst>
            <App />
        </StyledEngineProvider>

);