import * as React from 'react';
import {getGlobal, setGlobal} from './components/Globals';
// Joy import
import {CssVarsProvider, useColorScheme} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
// Icons import
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import teamTheme from './theme';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
// Component imports
import Main from './components/Main';
import Layout from './components/Layout';
import useToken from "./components/useToken";

import Login from './pages/login/Login';
import VideocamIcon from '@mui/icons-material/Videocam';

import PersonIcon from '@mui/icons-material/Person';
function ColorSchemeToggle() {
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="primary"/>;
    }
    return (
        <IconButton
            id="toggle-mode"
            size="sm"
            variant="outlined"
            color="primary"
            onClick={() => {
                if (mode === 'light') {
                    setMode('dark');
                } else {
                    setMode('light');
                }
            }}
        >
            {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
        </IconButton>
    );
}

function menuAdmin() {
    return (
        <List
            aria-labelledby="nav-list-browse"
            sx={{
                '& .JoyListItemButton-root': {p: '8px'},
            }}
        >

            <ListItem>
                <ListItemButton component="a" href="/progetti"  >

                    <ListItemDecorator >
                        <AccountTreeIcon fontSize="small"/>
                    </ListItemDecorator>
                    <ListItemContent>Progetti</ListItemContent>
                </ListItemButton>
            </ListItem>

            <ListItem>
                <ListItemButton component="a" href="/users"  >

                    <ListItemDecorator >
                        <GroupIcon fontSize="small"/>
                    </ListItemDecorator>
                    <ListItemContent>Utenti</ListItemContent>
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        component="a"
                        href="/users/aggiungi"
                    >
                        <AddIcon/>
                    </IconButton>
                </ListItemButton>
            </ListItem>

            <ListItem>
                <ListItemButton component="a" href="/email"  >

                    <ListItemDecorator >
                        <EmailIcon fontSize="small"/>
                    </ListItemDecorator>
                    <ListItemContent>Emails</ListItemContent>

                </ListItemButton>
            </ListItem>

        </List>
    )
}


function menuEditor() {
    return (
        <List
            aria-labelledby="nav-list-browse"
            sx={{
                '& .JoyListItemButton-root': {p: '8px'},
            }}
        >

            <ListItem>
                <ListItemButton component="a" href="/progetti"  >

                    <ListItemDecorator >
                        <AccountTreeIcon fontSize="small"/>
                    </ListItemDecorator>
                    <ListItemContent>Progetti</ListItemContent>
                </ListItemButton>
            </ListItem>

        </List>
    )
}

function menuUser () {
    return (
        <List
            aria-labelledby="nav-list-browse"
            sx={{
                '& .JoyListItemButton-root': {p: '8px'},
            }}
        >

            <ListItem>
                <ListItemButton component="a" href="/progetti/aggiungi"  >

                    <ListItemDecorator >
                        <AddIcon fontSize="small"/>
                    </ListItemDecorator>
                    <ListItemContent>Nuovo Progetto</ListItemContent>
                </ListItemButton>
            </ListItem>

            <ListItem>
                <ListItemButton component="a" href="/progetti"  >

                    <ListItemDecorator >
                        <AccountTreeIcon fontSize="small"/>
                    </ListItemDecorator>
                    <ListItemContent>I tuoi progetti</ListItemContent>
                </ListItemButton>
            </ListItem>

        </List>
    )
}

function menu() {

    let isAdmin = getGlobal("isAdmin");
    let isEditor = getGlobal("isEditor");
    if (isAdmin == '"true"' || isAdmin == 'true') {
        return menuAdmin();
    } else if (isEditor == '"true"' || isEditor == 'true') {
        return menuEditor()
    }
    return menuUser();
}

function TeamNav() {

    // @ts-ignore
    return (
        <List size="sm" sx={{'--List-item-radius': '8px', '--List-gap': '4px'}}>
            <ListItem nested>
                <ListSubheader>
                    Browse
                </ListSubheader>
                {menu()}
            </ListItem>
        </List>
    );
}

const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    // @ts-ignore
    return JSON.parse(tokenString);
};


export default function App() {

    const { token, setToken } = useToken();

    const [tmp, setTmp] = React.useState();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    if (!getToken()) {
        // @ts-ignore
        return <Login setToken={setToken} setTmp={setTmp}/>
    }

    function handleLogout () {
        sessionStorage.removeItem('token');
        setToken('');
    }


    return (
        <CssVarsProvider disableTransitionOnChange theme={teamTheme}>
            <CssBaseline/>
            {drawerOpen && (
                <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
                    <TeamNav/>
                </Layout.SideDrawer>
            )}
            <Layout.Root
                sx={{
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 1.5,
                        }}
                    >
                        <IconButton
                            variant="outlined"
                            size="sm"
                            onClick={() => setDrawerOpen(true)}
                            sx={{display: {sm: 'none'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <img src="/logo.svg" height="30" />

                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 1.5}}>
                        <ColorSchemeToggle/>
                        <IconButton
                            size="sm"
                            variant="outlined"
                            color="primary"
                            component="a"
                            onClick={handleLogout}
                        >
                            <LogoutIcon/>
                        </IconButton>
                    </Box>
                </Layout.Header>
                <Layout.SideNav>
                    <TeamNav/>
                </Layout.SideNav>

                <Layout.Main>
                    <Main/>
                </Layout.Main>
            </Layout.Root>
        </CssVarsProvider>
    );
}
