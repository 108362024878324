import '../App.css';
import React, {useState, useEffect} from 'react';
import {getGlobal, setGlobal} from '../../components/Globals';
import * as PropTypes from 'prop-types';
import Button from '@mui/joy/Button';
import {CssVarsProvider, useColorScheme} from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel, {formLabelClasses} from '@mui/joy/FormLabel';
import IconButton, {IconButtonProps} from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';

const Login = ({setTmp}: { setTmp: React.Dispatch<React.SetStateAction<string>> }) => {
    let [endpoint, setEndpoint] = useState(getGlobal("endpoint") + 'auth');

    const [username, setUsername] = React.useState();
    const [password, setPassword] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        setUsername(e.target.value);
    }

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        setPassword(e.target.value);
    }

    function handleLogin(event: { preventDefault: () => void; }) {

        event.preventDefault();
        setLoading(true);

        const details = {
            "username": username,
            "password": password,
        };

        const formBody = [];
        let finalBody;
        for (const property in details) {
            const encodedKey = encodeURIComponent(property);
            // @ts-ignore
            const encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        finalBody = formBody.join("&");
        fetch(`${endpoint}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: finalBody
        }).then((response) => response.json())
            .then((data) => {


                setLoading(false)

                if (!data.error) {
                    setTmp(data.token)
                    setGlobal({ isAdmin : data.isAdmin })
                    setGlobal({ isEditor : data.isEditor })
                    setGlobal({ userID : data.userId })
                    sessionStorage.setItem('token', JSON.stringify(data.token));
                    sessionStorage.setItem('userId', JSON.stringify(data.userId));
                    sessionStorage.setItem('isAdmin', JSON.stringify(data.isAdmin));
                    sessionStorage.setItem('isEditor', JSON.stringify(data.isEditor));
                }
            }).catch((err) => {
            console.log("error message " + err.message);
        });
    }

    interface FormElements extends HTMLFormControlsCollection {
        email: HTMLInputElement;
        password: HTMLInputElement;
        persistent: HTMLInputElement;
    }

    interface SignInFormElement extends HTMLFormElement {
        readonly elements: FormElements;
    }

    function ColorSchemeToggle({onClick, ...props}: IconButtonProps) {
        const {mode, setMode} = useColorScheme();
        const [mounted, setMounted] = React.useState(false);
        React.useEffect(() => {
            setMounted(true);
        }, []);
        if (!mounted) {
            return <IconButton size="sm" variant="plain" color="neutral" disabled/>;
        }
        return (
            <IconButton
                id="toggle-mode"
                size="sm"
                variant="plain"
                color="neutral"
                {...props}
                /*  onClick={(event) => {
                      if (mode === 'light') {
                          setMode('dark');
                      } else {
                          setMode('light');
                      }
                      onClick?.(event);
                  }}*/
            >
                {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
            </IconButton>
        );
    }

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <GlobalStyles styles={{
                ':root': {
                    '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                    '--Cover-width': '40vw', // must be `vw` only
                    '--Form-maxWidth': '700px',
                    '--Transition-duration': '0.4s', // set to `none` to disable transition
                },
            }}
            />
            <Box sx={(theme) => ({
                width:
                    'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                transition: 'width var(--Transition-duration)',
                transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                backdropFilter: 'blur(4px)',
                backgroundColor: 'rgba(255 255 255 / 0.6)',
                [theme.getColorSchemeSelector('dark')]: {
                    backgroundColor: 'rgba(19 19 24 / 0.4)',
                },
            })}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100dvh',
                    width:
                        'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                    maxWidth: '100%',
                    px: 2,
                }}>
                    <Box component="header" sx={{
                        py: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <img src="/logo.svg" width="80"/>

                    </Box>
                    <Box component="main" sx={{
                        my: 'auto',
                        py: 2,
                        pb: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: 400,
                        maxWidth: '100%',
                        mx: 'auto',
                        borderRadius: 'sm',
                        '& form': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        },
                        [`& .${formLabelClasses.asterisk}`]: {
                            visibility: 'hidden',
                        },
                    }}>
                        <div>
                            <Typography component="h2" fontSize="xl2" fontWeight="lg">
                                Bentornato
                            </Typography>
                            <Typography   sx={{my: 1, mb: 3}}>
                              Inserisci i tuoi dati.
                            </Typography>
                        </div>
                        <form onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                            event.preventDefault();
                            const formElements = event.currentTarget.elements;
                            const data = {
                                username: formElements.email.value,
                                password: formElements.password.value,
                            };
                            alert(JSON.stringify(data, null, 2));
                        }}
                        >
                            <FormControl required>
                                <FormLabel>Username</FormLabel>
                                <Input fullWidth required id="username" name="username" placeholder="Username"
                                       variant="outlined" onChange={handleUsername}/>
                            </FormControl>
                            <FormControl required>
                                <FormLabel>Password</FormLabel>
                                <Input fullWidth required type="password" id="password" name="password"
                                       placeholder="•••••••" variant="outlined" onChange={handlePassword}/>
                            </FormControl>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}></Box>
                            <Button className="plh_button" type="submit" fullWidth onClick={handleLogin}>Log in</Button>
                        </form>

                    </Box>
                    <Box component="footer" sx={{py: 3}}>
                        <Typography   textAlign="center">
                            © EPIC Srl {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={(theme) => ({
                height: '100%',
                position: 'fixed',
                right: 0,
                top: 0,
                bottom: 0,
                left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                transition:
                    'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                backgroundColor: 'background.level1',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage:
                    'url(https://www.plhitalia.com/wp-content/uploads/2021/09/Home_5.jpg)',
                [theme.getColorSchemeSelector('dark')]: {
                    backgroundImage:
                        'url(https://www.plhitalia.com/wp-content/uploads/2021/09/Home_5.jpg)',
                },
            })}/>
        </CssVarsProvider>
    )
}

Login.propTypes = {
    setTmp: PropTypes.func.isRequired
}

export default Login;