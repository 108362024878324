import * as React from 'react';
import {lazy, Suspense} from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {getGlobal, setGlobal} from './Globals';
const Home = lazy(() => import("../pages/home"));
const Users = lazy(() => import("../pages/users/list"));
const UsersEdit = lazy(() => import("../pages/users/edit"));
const UsersAdd = lazy(() => import("../pages/users/add"));
const User = lazy(() => import("../pages/account/user"));
const Progetti = lazy(() => import("../pages/progetti/list"));
const ProgettiAdd = lazy(() => import("../pages/progetti/add"));
const ProgettiSingle = lazy(() => import("../pages/progetti/single"));
const Email =  lazy(() => import("../pages/email/list"));
const EmailEdit =  lazy(() => import("../pages/email/edit"));

export const Main = () => {

    function menuAdmin () {

        return (
            <Suspense fallback={<div></div>}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Progetti/>}/>
                        <Route path="/account" element={<User/>}/>
                        <Route path="/users" element={<Users/>}/>
                        <Route path="/users/edit/:id" element={<UsersEdit/>}/>
                        <Route path="/users/aggiungi" element={<UsersAdd/>}/>
                        <Route path="/progetti/single/:id" element={<ProgettiSingle/>}/>
                        <Route path="/progetti" element={<Progetti/>}/>
                        <Route path="/email" element={<Email/>}/>
                        <Route path="/email/edit/:id" element={<EmailEdit/>}/>
                    </Routes>
                </BrowserRouter>
            </Suspense>
        )
    }

    function menuEditor () {

        return (
            <Suspense fallback={<div></div>}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Progetti/>}/>
                        <Route path="/progetti/single/:id" element={<ProgettiSingle/>}/>
                        <Route path="/progetti" element={<Progetti/>}/>
                    </Routes>
                </BrowserRouter>
            </Suspense>
        )
    }

    function menuUser() {

        return (
            <Suspense fallback={<div></div>}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<ProgettiAdd/>}/>
                        <Route path="/progetti/aggiungi" element={<ProgettiAdd/>}/>
                        <Route path="/progetti/single/:id" element={<ProgettiSingle/>}/>
                        <Route path="/progetti" element={<Progetti/>}/>
                      </Routes>
                </BrowserRouter>
            </Suspense>
        )
    }

    function menu() {

        let isAdmin = getGlobal("isAdmin");
        let isEditor = getGlobal("isEditor")
        if (isAdmin == '"true"' || isAdmin == 'true') {
            return menuAdmin();
        } else if (isEditor == '"true"' || isEditor == 'true') {
            return menuEditor();
        } else {
            return menuUser();
        }
    }

    return (
        menu()
    );
};

export default Main;